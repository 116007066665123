import styles from './central-popular-cities.module.css';

import * as React from 'react';

import { PopularCity } from 'core/entities/geo';

import Link from 'components/common/link/link';

interface CentralPopularCitiesProps {
  cities: Array<PopularCity>;
  openCalendar: (url: string, isMapUrl?: boolean) => void;
}

export const CentralPopularCities = ({ cities, openCalendar }: CentralPopularCitiesProps) => {
  const handleClick = (url: string) => {
    openCalendar(url, false);
  };

  return (
    <ul className={styles.root}>
      {cities.slice(0, 4).map((city) => {
        return (
          <li className={styles.item} key={city.id}>
            <span className={styles.link} onClick={() => handleClick(city.url)}>
              <>
                {city.name} <span className={styles.icon} />
              </>
            </span>
          </li>
        );
      })}
      <li className={styles.item}>
        <Link className={styles.link} href='/sitemap/' external>
          <>
            Все города <span className={styles.icon} />
          </>
        </Link>
      </li>
    </ul>
  );
};
