import * as Sentry from '@sentry/nextjs';
import get from 'lodash/get';

import { ExternalAccountResponse, YandexTokenInfo } from 'core/entities/auth-external';
import { APIService } from 'core/services';

export class AuthenticationError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'AuthenticationError';
  }
}

export class AuthService extends APIService {
  public fetchAccessToken = async (cookie?: Optional<string>): Promise<Optional<string>> => {
    try {
      const response = await this.execute('/api/v3/auth/tokens', 'GET', { customHeaders: { Cookie: cookie } });
      const accessToken: Optional<string> = get(response, 'headers.x-access-token', null);
      return accessToken;
    } catch (err) {
      const status: number = get(err, 'response.status', 0);
      if (status === 401) {
        throw new AuthenticationError('Unauthorized user');
      }
      throw err;
    }
  };

  public generateAuthUrl = async (next: string): Promise<{ url: string }> => {
    const response = await this.execute('/api/v3/auth/generate_url', 'POST', {
      payload: { next },
      withCredentials: true
    });
    return {
      url: get(response, 'data.url', {})
    };
  };
}

export class AuthYandexService extends APIService {
  async authWithYandexId(data: YandexTokenInfo): Promise<ExternalAccountResponse> {
    try {
      const response = await this.execute('/api/v3/auth/yandex/register', 'POST', {
        payload: data,
        withCredentials: true
      });
      const responseData: ExternalAccountResponse = get(response, 'data', null);

      return responseData;
    } catch (e) {
      const errorText = get(e, 'response.data.verbose', '');

      Sentry.captureException(e);
      throw new AuthenticationError(errorText);
    }
  }
}
