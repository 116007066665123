import { useContext, useState } from 'react';

import { CALENDAR_MODAL_CONTINUE_BUTTON_TEXT } from 'core/constants/filters';
import { FilterControl, Filters } from 'core/entities/filters';

import { FiltersContext } from 'contexts/filters/filters';

import { useFlatsRoutes } from 'hooks/router/use-flats-routes';

import { CalendarFooterWithSkip } from 'components/common/calendar-footer-with-skip/calendar-footer-with-skip';
import { CalendarModal } from 'components/search/controls/calendar/calendar-modal/calendar-modal';

interface СentralCalendarProps {
  calendar: FilterControl;
  mainHost: string;
  openMapUrl: boolean;
  urlToSearch: string;
  noMinDuration?: boolean;
  noCheckValidation?: boolean;
}

export const СentralCalendar = ({
  calendar,
  mainHost,
  urlToSearch,
  openMapUrl,
  noMinDuration,
  noCheckValidation
}: СentralCalendarProps) => {
  const { filters, setFilters } = useContext(FiltersContext);
  const [dates, setDates] = useState(filters.dates);
  const [calendarError, setCalendarError] = useState<Optional<string>>(null);
  const { goToFlatsListPage, goToFlatsMapPage } = useFlatsRoutes(mainHost);

  const filtersWithoutDates: Filters = {
    ...filters,
    dates: {
      arrival: null,
      departure: null,
      duration: null
    }
  };

  const onApply = (skipDates?: boolean) => {
    const currentFilters = skipDates ? filtersWithoutDates : { ...filters, dates };

    if (openMapUrl) {
      goToFlatsMapPage(currentFilters, urlToSearch);
    } else {
      goToFlatsListPage(currentFilters, urlToSearch);
    }
  };

  const onSkip = () => onApply(true);

  const footerChildren = <CalendarFooterWithSkip calendarError={calendarError} dates={dates} onSkip={onSkip} />;

  return (
    <CalendarModal
      btnText={CALENDAR_MODAL_CONTINUE_BUTTON_TEXT}
      calendar={calendar}
      calendarError={calendarError}
      dates={dates}
      mainHost={mainHost}
      filters={filters}
      footerChildren={footerChildren}
      setCalendarError={setCalendarError}
      setDates={setDates}
      setFilters={setFilters}
      onApply={onApply}
      noMinDuration={noMinDuration}
      noCheckValidation={noCheckValidation}
      urlToSearch={urlToSearch}
      applyWithoutDates
      withSkipBtn
    />
  );
};
