import styles from './yandex-auth-widget.module.css';

import * as Sentry from '@sentry/browser';
import { useContext, useEffect, useRef } from 'react';

import { YandexAuthPayload } from 'core/entities/auth-external';
import { AuthenticationError } from 'core/services/auth';

import { CommonContext } from 'contexts/common';

import { useScript } from 'hooks/index';

interface InitiationResponse {
  status: string;
  code?: string;
  handler?: () => Promise<YandexAuthPayload>;
}

interface OauthQueryParams {
  client_id: string;
  response_type: string;
  redirect_uri: string;
}

// неполная типизация, требует eslint
interface YaAuthSuggest {
  init: (oauthQueryParams: OauthQueryParams, tokenPageOrigin: string) => Promise<InitiationResponse>;
}

interface YandexAuthWidgetProps {
  yandexIdClientId: string;
  onAuth: (data: YandexAuthPayload) => Promise<void>;
}

const YANDEX_WIDGET_ID = 'yandexAuthWidget';
const SCRIPT_SRC = 'https://yastatic.net/s3/passport-sdk/autofill/v1/sdk-suggest-with-polyfills-latest.js';

export const YandexAuthWidget = ({ yandexIdClientId, onAuth }: YandexAuthWidgetProps) => {
  const yandexButtonRef = useRef<HTMLDivElement>(null);
  const status = useScript(SCRIPT_SRC);

  const { context } = useContext(CommonContext);
  const mainHost = context && context.host ? context.host : '';

  const oauthQueryParams = {
    client_id: yandexIdClientId,
    response_type: 'token',
    redirect_uri: `https://${mainHost}/account/auth/yandex/`
  };

  const onLoadScript = async () => {
    if (!yandexButtonRef.current) {
      return;
    }

    try {
      // eslint-disable-next-line
      // @ts-ignore
      const yaAuthSuggest: YaAuthSuggest = window.YaAuthSuggest;
      const initiationResult = await yaAuthSuggest.init(oauthQueryParams, `https://${mainHost}`);

      if (initiationResult.handler) {
        const authSubscriptionData = await initiationResult.handler();
        await onAuth(authSubscriptionData);
      }
    } catch (e) {
      if (e instanceof AuthenticationError) {
        setTimeout(() => onLoadScript(), 0);
      } else {
        Sentry.captureException(e);
      }
    }
  };

  useEffect(() => {
    if (status === 'ready') {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      onLoadScript();
    }
  }, [status]);

  return (
    <div className={styles.yandexAuthWidgetContent}>
      <div id={YANDEX_WIDGET_ID} className={styles.yandexAuthWidget} ref={yandexButtonRef} />
    </div>
  );
};
