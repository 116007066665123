import styles from './calendar-footer-with-skip.module.css';

import { CALENDAR_MODAL_SKIP_BUTTON_TEXT } from 'core/constants/filters';
import { DatesFilters } from 'core/entities/filters';

import { Button } from 'components/common/button/button';
import { RangeInfo } from 'components/search/controls/calendar/range-info/range-info';

interface CalendarFooterWithSkipProps {
  calendarError: Optional<string>;
  dates: DatesFilters;
  onSkip: () => void;
}

export const CalendarFooterWithSkip = ({ calendarError, dates, onSkip }: CalendarFooterWithSkipProps) => {
  return (
    <div className={styles.calenderFooterChildren}>
      <div className={styles.calenderSkipButton}>
        <Button variant='text' text={CALENDAR_MODAL_SKIP_BUTTON_TEXT} onClick={onSkip} block />
      </div>
      <RangeInfo dates={dates} calendarError={calendarError} withSkipBtn />
    </div>
  );
};
