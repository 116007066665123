import get from 'lodash/get';
import { useCallback } from 'react';

import { errorText } from 'core/constants/error-text';
import { AuthYandexService, AuthenticationError } from 'core/services/auth';

export interface YandexAuthPayload {
  access_token: string;
  expires_in: string;
  token_type: 'bearer';
}

export function useLoginYandex(mainHost: string, correlationID: string, onSuccess: () => void) {
  const onSubmit = useCallback(
    async (data: YandexAuthPayload) => {
      let response = null;
      const service = new AuthYandexService(mainHost, correlationID);

      try {
        response = await service.authWithYandexId({
          accessToken: data.access_token,
          expiresIn: Number(data.expires_in),
          tokenType: data.token_type
        });
      } catch (err) {
        if (err instanceof AuthenticationError) {
          const message = get(err, 'message', errorText.defaultError);
          throw new AuthenticationError(message);
        } else {
          throw new AuthenticationError(errorText.defaultLoginTextError);
        }
      }

      if (response) {
        onSuccess();
      }
    },
    [onSuccess]
  );
  return onSubmit;
}
