/* eslint-disable complexity */
import styles from './central-page.module.css';

import classNames from 'classnames';
import get from 'lodash/get';
import { useRouter } from 'next/router';
import { Dispatch, SetStateAction, useCallback, useContext, useState } from 'react';

import { CommonPageContext, CentralPageContext } from 'core/services/context/context';

import { FilterControlsContext } from 'contexts/filters/filter-control';

import { useLoginYandex } from 'hooks/auth/use-yandex-login';
import { useBreakpoints } from 'hooks/use-window-dimensions';

import { YandexAuthWidget } from 'components/auth-external/yandex/yandex-auth-widget';
import { СentralCalendar } from 'components/central-page/central-calendar/central-calendar';
import { CentralPopularCities } from 'components/central-page/central-popular-cities/central-popular-cities';
import Link from 'components/common/link/link';
import { Price } from 'components/common/price/price';
import { Text, TextColors } from 'components/common/text/text';
import { SearchBar } from 'components/search-bar/search-bar';

interface CentralPageProps {
  context: {
    common: CommonPageContext;
    extra: CentralPageContext;
  };
  isTransparentHead: boolean;
  setIsTransparentHead: Dispatch<SetStateAction<boolean>>;
}

export const CentralPage = ({ context, isTransparentHead, setIsTransparentHead }: CentralPageProps) => {
  const { common, extra } = context;
  const mainHost = common.host || '';
  const correlationID = get(context, 'req.headers.x-request-id', '') as string;

  const { isMobile } = useBreakpoints();
  const { calendar } = useContext(FilterControlsContext);
  const router = useRouter();
  const [urlToSearch, setUrlToSearch] = useState('');
  const [openMapUrl, setOpenMapUrl] = useState(true);

  const setTransparentHead = useCallback((param: boolean) => setIsTransparentHead(!param), []);
  const openCalendarModal = (url: string, isMapUrl = true) => {
    setUrlToSearch(url);
    setOpenMapUrl(isMapUrl);

    setTimeout(() => {
      calendar.onOpen();
    }, 0);
  };

  const onLogin = () => {
    router.reload();
  };

  const onYandexAuth = useLoginYandex(mainHost, correlationID, onLogin);

  return (
    <>
      <div
        className={styles.wrapper}
        style={{
          backgroundImage: `
              linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
              url(${extra.backgroundURL})
            `
        }}
      >
        <div className={styles.blackout}>
          <div className={styles.content}>
            <Text As='h1' className={styles.title} size={20} desktopSize={28} color={TextColors.white}>
              {common.seo.headline}
            </Text>
            <div
              className={classNames(styles.searchbarWrapper, {
                [styles.mobileActiveSearch]: isMobile && !isTransparentHead
              })}
            >
              <SearchBar
                mainHost={mainHost}
                setSearchbarActive={setTransparentHead}
                onSubmit={openCalendarModal}
                isCentral
              />
              {!isMobile && <CentralPopularCities cities={extra.popularCities} openCalendar={openCalendarModal} />}
              <СentralCalendar
                calendar={calendar}
                mainHost={mainHost}
                urlToSearch={urlToSearch}
                openMapUrl={openMapUrl}
              />
            </div>
          </div>
          {extra.lodging && (
            <div className={styles.lodging}>
              <Link href={extra.lodging.url} className={styles.lodgingLink} external={true}>
                <>
                  <Text As='p' size={16} desktopSize={16} color={TextColors.white}>
                    {extra.lodging.rooms || 'Квартира'} {extra.lodging.city ? `в ${extra.lodging.city}` : ''}
                  </Text>
                  {extra.lodging.price && (
                    <Text As='p' size={16} desktopSize={16} color={TextColors.white}>
                      <Price currency={common.currencies.current} value={extra.lodging.price} /> в сутки
                    </Text>
                  )}
                </>
              </Link>
            </div>
          )}
        </div>
      </div>
      {!isMobile && !common.user?.id && (
        <YandexAuthWidget yandexIdClientId={common.yandexIdClientId} onAuth={onYandexAuth} />
      )}
      {isMobile && <CentralPopularCities cities={extra.popularCities} openCalendar={openCalendarModal} />}
    </>
  );
};
